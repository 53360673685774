<template>
  <section class="product">
    <div class="banner">
      <img :src="$t('locale') == 'zh' ? banner : banner_en" alt="" />
    </div>
    <section class="main_contain">
      <figure>
        <figcaption>{{ $t("PC.Product.index.gzu3rj") }}</figcaption>
          <div class="box1">
          <img src="../../../assets/zh/images/productd_img9.jpg" alt="" />
          <article>
            <p>{{ $t("PC.Product.index.2d06a1") }}</p>
            <span>{{ $t("PC.Product.index.8eda57") }}</span>
            <router-link class="more" to="/CoovallyHome">{{
              $t("PC.Product.index.c09306")
            }}</router-link>
          </article>
         
        </div>
        <div class="box2">
          <article>
            <p>{{ $t("PC.Product.index.h3g3ll") }}</p>
            <span>{{ $t("PC.Product.index.jkkevd") }}</span>
            <router-link class="more" to="/AIShop">{{
              $t("PC.Product.index.bojcor")
            }}</router-link>
          </article>
          <img src="../../../assets/zh/images/productd_img1.jpg" alt="" />
        </div>
        <!-- <div class="box3">
          <img src="../../../assets/zh/images/productd_img4.jpg" alt="" />
          <article>
            <p>{{ $t("PC.Product.index.a3vyku") }}</p>
            <span>{{ $t("PC.Product.index.zz1uhs") }}</span>
          </article>
        
        </div> -->
        <!-- <div class="box4">
         
          <article>
            <p>{{ $t("PC.Product.index.y31rsa") }}</p>
            <span>{{ $t("PC.Product.index.rvy2c7") }}</span>
            <router-link class="more" to="POMA">{{
              $t("PC.Product.index.bojcor")
            }}</router-link>
          </article>
         <img src="../../../assets/zh/images/productd_img5.jpg" alt="" />
        </div> -->
        <div class="box5">
          <img src="../../../assets/images/productd_img8.jpg" alt="" />
          <article>
            <p>{{ $t("PC.Product.index.5pvjc1") }}</p>
            <span>{{ $t("PC.Product.index.zqerls") }}</span>
            <router-link class="more" to="Slager">{{
              $t("PC.Product.index.qepv5g")
            }}</router-link>
          </article>

        </div>
      </figure>
      <FooterC></FooterC>
    </section>
  </section>
</template>

<script>
import FooterC from "../components/FooterC";
export default {
  data() {
    return {
      banner: require("@/assets/zh/images/productd_banner1@2x.jpg"),
      banner_en: require("@/assets/en/images/productd_banner1@2x.jpg")
    };
  },
  components: {
    FooterC
  },
  methods: {
    learnMmore(pah) {
      this.$router.push({
        path: pah
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  margin-top: 70Px;
  background-color: #f1f2f6;
  word-break: break-word !important;

  .banner {
    width: 100%;
    height: 440Px;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1920Px;
      height: 440Px;
      margin: 0 auto;
    }
  }

  .main_contain {
    position: absolute;
    top: 510Px;
    width: 100%;
    background-color: #fff;

    figure {
      margin: 0 auto;
      width: 960Px;
      background-color: #fff;
      margin-bottom: 100Px;

      figcaption {
        padding: 72Px 0 40Px;
        font-size: 32Px;
        color: #333333;
        line-height: 45Px;
        text-align: center;
      }

      & > div {
        display: flex;
        margin-bottom: 48Px;

        article {
          width: 480Px;
          background-image: url("../../../assets/zh/images/productd_img2.png");
          background-size: 100% 100%;
          padding: 60Px 30Px 40Px 30Px;
          box-sizing: border-box;
          border-bottom: 4Px solid #224386;

          p {
            font-size: 28Px;
            color: #333333;
            line-height: 42Px;
            margin-bottom: 16Px;
          }

          span {
            display: block;
            width: 418Px;
            font-size: 16Px;
            font-family:  PingFangSC-Regular, PingFang SC;
            color: #666666;
            line-height: 28Px;
            overflow: hidden;
          }

          .more {
            display: inline-block;
            height: 36Px;
            border: 1Px solid #666666;
            font-size: 14Px;
            font-family:  PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20Px;
            box-sizing: border-box;
            padding: 8Px 24Px;
            margin-top: 24Px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
     .box1 {
        article {
          background-image: url("../../../assets/zh/images/productd_img10.png");
        }
      }

     .box2 {
        article {
          background-image: url("../../../assets/zh/images/productd_img2.png");
        }
      }

      .box3 {
        article {
          background-image: url("../../../assets/zh/images/productd_img3.png");
        }
      }

      .box4 {
        article {
          background-image: url("../../../assets/zh/images/productd_img6.png");
        }
      }

      .box5 {
        article {
          background-image: url("../../../assets/images/productd_img7.jpg");
        }
      }
    }
  }
}
</style>
